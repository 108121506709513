import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikErrors, FormikHelpers, FormikProps} from 'formik';

import {FormikCheckboxGroup, FormikDateInput, FormikInput, FormikSelect, useAlerts} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {courseApi} from '../../api';
import {Course, CourseFormFields} from '../../types';
import {courseFormSchema} from '../../schema';
import {times} from 'lodash';
import {memo, useCallback, useMemo} from 'react';

type Props = {
  isOpen: boolean
  operation: 'Add' | 'Edit'
  certificationYears: number[]
  course: Course | undefined
  onToggle: (operation: 'Add' | 'Edit', course?: Course) => void
}

const CourseModal = ({
                       isOpen,
                       operation,
                       certificationYears,
                       course,
                       onToggle
                     }: Props) => {
  const {showErrorAlert, showSuccessAlert} = useAlerts();

  const initialValues: CourseFormFields = useMemo(() => ({
    certificationYear: course?.certificationYear ?? '',
    courseNumber: course?.courseNumber ?? '',
    name: course?.name ?? '',
    courseDate: course?.courseDate ?? null,
    approvedHours: course?.approvedHours ?? '',
    instructor: course?.instructor ?? '',
    organization: course?.organization ?? '',
    contact: course?.contact ?? '',
    locked: course?.locked ?? false,
    stcUpdate: course?.stcUpdate ?? null,
    classFormat: course?.classFormat ?? null
  }), [
    course
  ]);

  const handleSubmit = useCallback(async (courseChangeRequest: CourseFormFields,
                                          formikHelpers: FormikHelpers<CourseFormFields>) => {
    try {
      if (operation === 'Add') {
        const newCourse = await courseApi.create(courseChangeRequest);
        onToggle(operation, newCourse);
        showSuccessAlert(messages.COURSE_ADD_SUCCESSFUL);
      } else {
        const editedCourse = await courseApi.update(course?.id ?? -1, courseChangeRequest);
        onToggle(operation, editedCourse);
        showSuccessAlert(messages.COURSE_EDIT_SUCCESSFUL);
      }
      formikHelpers.resetForm();
      formikHelpers.setSubmitting(false);
    } catch (error: any) {
      const errorWithType = error as {status: number, validationMessages: FormikErrors<CourseFormFields>};
      if (errorWithType.status === 409) {
        formikHelpers.setFieldError('courseNumber', messages.DUPLICATE_COURSE_EXISTS);
      } else if (errorWithType.status === 422) {
        // Will come back from the API by virtue of Spring validation messages
        formikHelpers.setErrors(errorWithType.validationMessages);
      } else {
        if (operation === 'Add') {
          showErrorAlert(messages.COURSE_ADD_FAILED);
        } else {
          showErrorAlert(messages.COURSE_EDIT_FAILED);
        }
      }
      formikHelpers.setSubmitting(false);
    }
  }, [operation, course, onToggle, showErrorAlert, showSuccessAlert]);

  const handleClose = useCallback((formikProps: FormikProps<CourseFormFields>) => {
    formikProps.resetForm();
    onToggle(operation);
  }, [onToggle, operation]);

  // Render 1-16 hr options for approved hours
  const approvedHourOptions = useMemo(() => times(16, (index) =>
    <option value={index + 1}>{index + 1}</option>), []);

  return (
    <Formik initialValues={initialValues}
            enableReinitialize
            validateOnMount={true}
            validationSchema={courseFormSchema}
            onSubmit={handleSubmit}>
      {(formikProps) => {
        return (
          <Modal className="CourseModal"
                 autoFocus={false}
                 backdrop="static"
                 size="xl"
                 isOpen={isOpen}
                 toggle={() => handleClose(formikProps)}>
            <ModalHeader toggle={() => handleClose(formikProps)}>
              {operation === 'Add' ? 'Add Course' : 'Edit Course'}
            </ModalHeader>
            <Form onSubmit={formikProps.handleSubmit}
                  autoComplete="off">
              <ModalBody>
                <Row>
                  <Col md="4">
                    <FormikSelect name="certificationYear"
                                  labelText="Certification Year"
                                  autoFocus
                                  aria-required={true}>
                      <option value="">Select</option>
                      {certificationYears.map(certificationYear =>
                        <option value={certificationYear}>{certificationYear}</option>)}
                    </FormikSelect>
                  </Col>
                  <Col md="4">
                    <FormikSelect name="stcUpdate"
                                  labelText="STC Updates"
                                  aria-required={true}>
                      <option value="">Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </FormikSelect>
                  </Col>
                  <Col md="4">
                    <FormikSelect name="classFormat"
                                  labelText="Class Format"
                                  aria-required={true}>
                      <option value="">Select</option>
                      <option value="LIVE_SESSION">Live Session</option>
                      <option value="SELF_PACED">Self-paced</option>
                    </FormikSelect>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormikInput name="courseNumber"
                                 labelText="Course Number"
                                 aria-required={true}/>
                  </Col>
                  <Col md="6">
                    <FormikInput name="name"
                                 labelText="Course Name"
                                 aria-required={true}/>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <FormikDateInput name="courseDate"
                                     labelText="Course Date"/>
                  </Col>
                  <Col md="4">
                    <FormikSelect name="approvedHours"
                                  labelText="Course Hours"
                                  aria-required={true}>
                      <option value="">Select</option>
                      {approvedHourOptions}
                    </FormikSelect>
                  </Col>
                  <Col md="4">
                    <FormikInput name="instructor"
                                 labelText="Instructor"
                                 aria-required={true}/>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <FormikInput name="organization"
                                 labelText="Organization"
                                 aria-required={true}/>
                  </Col>
                  <Col md="6">
                    <FormikInput name="contact"
                                 labelText="Contact Information"/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormikCheckboxGroup type="switch"
                                         checkboxes={[
                                           {name: 'locked', labelText: 'Locked'}
                                         ]}/>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="success"
                        onClick={formikProps.submitForm}
                        disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                  {operation === 'Add' ? 'Add' : 'Save'}
                </Button>
                <Button color="secondary"
                        onClick={() => handleClose(formikProps)}
                        disabled={formikProps.isSubmitting}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(CourseModal);
