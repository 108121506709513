import * as Yup from 'yup';

import * as messages from '../messages';

export default Yup.object().shape({
  certificationYear: Yup.number()
    .required(messages.REQUIRED),
  courseNumber: Yup.string()
    .required(messages.REQUIRED),
  name: Yup.string()
    .required(messages.REQUIRED),
  courseDate: Yup.date()
    .nullable(),
  approvedHours: Yup.number()
    .required(messages.REQUIRED),
  instructor: Yup.string()
    .required(messages.REQUIRED),
  organization: Yup.string()
    .required(messages.REQUIRED),
  contact: Yup.string(),
  locked: Yup.bool(),
  stcUpdate: Yup.bool()
    .required(messages.REQUIRED)
    .nullable(),
  classFormat: Yup.string()
    .required(messages.REQUIRED)
    .oneOf(['LIVE_SESSION', 'SELF_PACED', null])
    .nullable()
});